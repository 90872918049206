import cn from 'classnames'
import React from 'react'
import { useConfigurator } from '../src/contexts/ConfiguratorContext'
import { ImageFragment } from '../src/model/fragments'
import { Picture } from './Picture'
import SvgMascotHeader from './icons/SvgMascotHeader'

export interface IntroProps {
	title: React.ReactNode
	subtitle?: React.ReactNode
	additionalInfo?: React.ReactNode | React.ReactNode[]
	image?: ImageFragment
}

export const Intro: React.FunctionComponent<IntroProps> = ({
	title,
	children,
	subtitle,
	additionalInfo,
	image,
}) => {	
	const additionalInfoArray =
		additionalInfo && Array.isArray(additionalInfo)
			? additionalInfo
			: [additionalInfo]

	const { isConfigurator } = useConfigurator()
	return (
		<div
			className={cn('intro', image && 'view-withImage', children && 'has-lead', isConfigurator && 'has-configurator' )}
		>
			<div className="intro-in">
				<SvgMascotHeader mobile={true} />
				
				{image && (
					<div className="intro-image">
						<Picture
							image={image}
							width={485}
							height={485}
							alt=""
						/>
					</div>
				)}

				<div className="intro-content">
					
					<h1 className="intro-title">{title}<SvgMascotHeader mobile={false} /></h1>
					{subtitle && <div className="intro-subtitle">{subtitle}</div>}
					{children && <div className="intro-lead">{children}</div>}
					{additionalInfo && (
						<div className="intro-additionalInfo">
							{additionalInfoArray.map((item, i) => (
								<React.Fragment key={i}>
									{i !== 0 && (
										<>
											{' '}
											<span className="intro-additionalInfo-separator">
												•
											</span>{' '}
										</>
									)}
									{item}
								</React.Fragment>
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	)
}
