import classNames from 'classnames'
import * as React from 'react'

type SvgMascotHeaderProps = React.SVGProps<SVGSVGElement> & {
	mobile?: boolean
}

function SvgMascotHeader(props: SvgMascotHeaderProps) {
	return (
		<svg className={classNames('mascot', props.mobile && 'mascot--mobile')} id="Vrstva_1" data-name="Vrstva 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180.83 138.67">
		<g>
			<polygon points="92.17 112.38 60.34 112.38 48.2 124.51 48.2 137 80.04 137 92.17 124.86 92.17 112.38" style={{ fill: "#c4c4c4", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
			<polyline points="48.22 124.53 80.02 124.53 80.02 136.98" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
			<line x1="80.02" y1="124.53" x2="92.17" y2="112.38" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
			<polygon points="92.17 112.38 60.34 112.38 48.2 124.51 48.2 137 80.04 137 92.17 124.86 92.17 112.38" style={{ fill: "#c4c4c4", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
			<polyline points="48.22 124.53 80.02 124.53 80.02 136.98" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
			<line x1="80.02" y1="124.53" x2="92.17" y2="112.38" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
		</g>
		<g>
			<polygon points="149.97 112.38 118.14 112.38 106 124.51 106 137 137.83 137 149.97 124.86 149.97 112.38" style={{ fill: "#c4c4c4", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
			<polyline points="106.01 124.53 137.81 124.53 137.81 136.98" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
			<line x1="137.81" y1="124.53" x2="149.97" y2="112.38" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
			<polygon points="149.97 112.38 118.14 112.38 106 124.51 106 137 137.83 137 149.97 124.86 149.97 112.38" style={{ fill: "#c4c4c4", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
			<polyline points="106.01 124.53 137.81 124.53 137.81 136.98" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
			<line x1="137.81" y1="124.53" x2="149.97" y2="112.38" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
		</g>
		<g>
			<polygon points="77.07 1.67 56.15 22.58 56.15 85.34 77.07 106.25 139.82 106.25 160.74 85.34 160.74 22.58 139.82 1.67 77.07 1.67" style={{ fill: "#c4c4c4", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
			<polygon points="66.99 11.79 46.07 32.71 46.07 95.46 66.99 116.38 129.74 116.38 150.65 95.46 150.65 32.71 129.74 11.79 66.99 11.79" style={{ fill: "#c4c4c4", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
			<line x1="150.61" y1="32.69" x2="160.72" y2="22.59" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
			<line x1="129.68" y1="11.76" x2="139.79" y2="1.66" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
		</g>
		<g>
			<line x1="106.44" y1="49.62" x2="89.59" y2="49.57" style={{ fill: "#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
			<polygon points="59.56 42.11 92.95 42.11 92.95 53.24 80.23 65.9 72.25 65.9 59.56 53.24 59.56 42.11" style={{ fill: "#159934", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
			<polygon points="103.1 42.12 136.49 42.1 136.49 53.24 123.78 65.9 115.8 65.9 103.11 53.25 103.1 42.12" style={{ fill: "#159934", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
			<line x1="41.46" y1="48.32" x2="65.08" y2="48.32" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
			<line x1="131.64" y1="48.32" x2="155.27" y2="48.32" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
		</g>
		<g>
			<rect x="73.59" y="46.19" width="6.37" height="6.37" rx="3.18" ry="3.18" />
			<rect x="117.11" y="46.19" width="6.37" height="6.37" rx="3.18" ry="3.18" />
		</g>
		<polyline points="67.01 33.04 84.34 33.06 92.02 25.38" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
		<line x1="110.16" y1="20.45" x2="124.07" y2="34.37" style={{ fill: "#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
		<g>
			<polygon points="123.83 77.55 123.83 79.21 109.29 93.75 87.43 93.75 72.89 79.21 72.89 77.55 123.83 77.55" style={{ fill: "#159934", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
			<polygon points="81.14 77.55 123.83 77.55 123.83 79.21 119.52 83.52 87.11 83.52 81.14 77.55" style={{ fill: "#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
			<g>
				<line x1="72.88" y1="72.72" x2="72.88" y2="84.42" style={{ fill: "#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
				<line x1="123.85" y1="72.72" x2="123.85" y2="84.42" style={{ fill: "#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
			</g>
		</g>
		<line x1="85.59" y1="101.83" x2="111.14" y2="101.83" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
		<g>
			<polygon points="145.08 83.12 151.02 89.06 143.13 96.95 148.97 102.79 155.84 109.66 165.17 109.66 179.17 95.67 179.17 86.34 165.17 72.35 155.84 72.35 145.08 83.12" style={{ fill: "#c4c4c4", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
			<line x1="148.92" y1="102.61" x2="159.91" y2="91.62" style={{ fill: "#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
			<line x1="154.95" y1="108.44" x2="165.85" y2="97.55" style={{ fill: "#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
			<line x1="151.09" y1="88.99" x2="157.96" y2="82.12" style={{ fill: "#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
		</g>
		<g>
			<polygon points="9.43 68.63 17.15 76.36 2.36 91.15 8.2 96.99 12.86 92.32 24.4 103.87 38.4 89.87 38.4 80.54 22.62 64.77 13.29 64.77 9.43 68.63" style={{ fill: "#c4c4c4", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
			<polyline points="8.65 96.52 20.08 85.08 31.67 96.68" style={{ fill: "none", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
			<line x1="18.7" y1="98.12" x2="25.91" y2="90.91" style={{ fill: "#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "3.34px" }} />
		</g>
	</svg>
	)
}

export default SvgMascotHeader
